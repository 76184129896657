import { EgmRCEnums } from '@/constants/enums/api/egm-rc.enums'
import { ApiFactory, type ApiResponse } from '@/utils/api'

export class EgmRcServices extends ApiFactory {
  constructor() {
    super('')
  }

  // 取得EGM選單設定檔
  getEgmRcInfo = async (params: { pcbId: string }): Promise<ApiResponse> => {
    return await this.get<unknown, ApiResponse>(EgmRCEnums.egmRcInfo, params)
  }

  // 取得EGM回報事件清單
  getEgmRcEventP = async (params: {
    pcbId: string
    pageSize: number
    pageIndex: number
  }): Promise<ApiResponse> => {
    return await this.get<unknown, ApiResponse>(EgmRCEnums.egmRcEventP, params)
  }

  // 取得EGM的帳目內值
  getEgmRcMeters = async (params: { pcbId: string }): Promise<ApiResponse> => {
    return await this.get<unknown, ApiResponse>(EgmRCEnums.egmRcMeters, params)
  }

  // 取得SERVER儲存的設定內值
  getEgmRcSettingS = async (params: { pcbId: string }): Promise<ApiResponse> => {
    return await this.get<unknown, ApiResponse>(EgmRCEnums.egmRcSettingS, params)
  }

  // 取得EGM回報的設定內值
  getEgmRcSettingM = async (params: { pcbId: string }): Promise<ApiResponse> => {
    return await this.get<unknown, ApiResponse>(EgmRCEnums.egmRcSettingM, params)
  }

  // 遠端控制機台
  machineRcAction = async (
    params: {
      pcbId: string
      type: number
      cmd: number
      count: number
    }[]
  ): Promise<ApiResponse> => {
    return await this.post<unknown, ApiResponse>(EgmRCEnums.machineRcAction, params)
  }
}
