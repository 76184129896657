<script lang="ts" setup>
/* import */
import { ref, computed } from 'vue'
import { Bar } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import type { ChartOptions, ChartData } from 'chart.js'
import type { StoresTotalResType } from '@/models/types/store.types'
import { useDate } from '@/composables/useDate'
import { useI18n } from 'vue-i18n'

/* i18n */
const { t: $t } = useI18n()

/* Props (defineProps) */
const props = defineProps<{
  isInitialChart: Boolean
  isLoading: Boolean
  data: StoresTotalResType
  tabPage: 'revenue' | 'profit'
}>()

/* 非響應式變數 */
const rootStyles = getComputedStyle(document.documentElement)
const barDefaultColor = rootStyles.getPropertyValue('--bar-default-color').trim()
const barTargetColor = rootStyles.getPropertyValue('--bar-target-color').trim()
const revenueBgColor = rootStyles.getPropertyValue('--bg-revenue-color').trim()

/* 子組件 ref */
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

/* ref 變數 */

let tooltipInfo: { date: string; day: string; labelValue: number }[] | null = null

const chartOptions = ref<ChartOptions<'bar'>>({
  responsive: true,
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      backgroundColor: '#fff',
      titleColor: '#646464',
      bodyColor: '#323232',
      padding: 10,
      cornerRadius: 8,
      callbacks: {
        title: (context) => {
          const dateTitle = tooltipInfo
            ? formatDate(tooltipInfo[context[0].dataIndex].date, 'YYYY-MM-DD')
            : ''
          return dateTitle
        },
        label: (context) => {
          const labelValue = tooltipInfo ? tooltipInfo[context.dataIndex].labelValue : ''
          return `${labelValue}`
        },
        labelColor: (context) => {
          const backgroundColorArray = context.dataset?.backgroundColor as string[] | undefined
          const backgroundColor = backgroundColorArray?.[context.dataIndex] || '#000'
          return {
            borderColor: 'rgba(255, 255, 255, 0)',
            backgroundColor: backgroundColor,
            borderWidth: 0,
            borderRadius: 5
          }
        }
      }
    }
  },
  scales: {
    x: {
      display: true,
      ticks: {
        color: 'white'
      }
    },
    y: {
      beginAtZero: true,
      ticks: {
        display: false
      },
      grid: {
        display: true,
        drawOnChartArea: true,
        drawTicks: false,
        color: (context) => (context.tick.value === 0 ? revenueBgColor : revenueBgColor),
        lineWidth: (context) => (context.tick.value === 0 ? 2 : 1)
      },
      border: {
        display: false
      }
    }
  }
})

/* computed */
const { formatDate, today, getDayOfWeek } = useDate()

const setTooltipInfo = (data: StoresTotalResType) => {
  tooltipInfo = data?.map((item) => {
    const date = formatDate(item.date, 'YYYY-MM-DD')
    const day = getDayOfWeek(item.date).toString()
    const labelValue = props.tabPage === 'revenue' ? item.revenue : item.profit

    return {
      date,
      day,
      labelValue
    }
  })
}

// x 軸的初始標籤
const xLabels = [
  $t('IndexBaseBarChart.DayOfWeek.0'),
  $t('IndexBaseBarChart.DayOfWeek.1'),
  $t('IndexBaseBarChart.DayOfWeek.2'),
  $t('IndexBaseBarChart.DayOfWeek.3'),
  $t('IndexBaseBarChart.DayOfWeek.4'),
  $t('IndexBaseBarChart.DayOfWeek.5'),
  $t('IndexBaseBarChart.DayOfWeek.6')
]

// 當使用 Filter 時，x 軸的標籤
const xLabelsWhenUsingFilter = (data: StoresTotalResType): string[] => {
  return data?.map((item) => {
    return ''
  })
}

// y 軸的資料
const yData = (data: StoresTotalResType): number[] => {
  setTooltipInfo(data)

  return data?.map((item) => (props.tabPage === 'revenue' ? item.revenue : Math.abs(item.profit)))
}

// y 軸的背景顏色
const yBackgroundColor = (data: StoresTotalResType): string[] => {
  return data?.map((item) => {
    if (props.tabPage === 'profit' && 'profit' in item && item.profit < 0) {
      return 'red'
    }

    const nowDate = formatDate(item.date, 'YYYY-MM-DD')
    return nowDate === today() ? barTargetColor : barDefaultColor
  })
}

const chartData = computed<ChartData<'bar'>>(() => {
  let data = props.data

  if (props.isInitialChart) {
    const todayNumber = getDayOfWeek(today())
    data = data?.slice(data?.length - (todayNumber + 1))
  }

  return {
    labels: props.isInitialChart ? xLabels : xLabelsWhenUsingFilter(data),
    datasets: [
      {
        data: yData(data),
        backgroundColor: yBackgroundColor(data),
        borderRadius: 6
      }
    ]
  }
})
</script>

<template>
  <div class="chartSection">
    <Bar
      v-if="!props.isLoading"
      id="my-chart-id"
      :options="chartOptions"
      :data="chartData"
    />
  </div>
</template>

<style lang="scss" scoped>
.chartSection {
  width: 100%;
  position: relative;
}
</style>
